<template>
  <div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <app-admin-dashboard v-if="userLevel === '1' || userLevel === 1" />
        <app-tutor-dashboard v-if="userLevel === '2' || userLevel === 2" />
        <app-learner-dashboard v-if="userLevel === '3' || userLevel === 3" />
        <app-iqa-dashboard v-if="userLevel === '4' || userLevel === 4" />
      </div>
    </div>
  </div>
</template>

<script>
import AdminDashboard from '@/components/dashboards/Admin.vue';
import TutorDashboard from '@/components/dashboards/Tutor.vue';
import LearnerDashboard from '@/components/dashboards/Learner.vue';
import IqaDashboard from '@/components/dashboards/Iqa.vue';

export default {
  name: 'Dashboard',
  components: {
    appAdminDashboard: AdminDashboard,
    appTutorDashboard: TutorDashboard,
    appLearnerDashboard: LearnerDashboard,
    appIqaDashboard: IqaDashboard,
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    userLevel() {
      return this.$store.getters.userLevel;
    },
  },
};
</script>

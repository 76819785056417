<!-- eslint-disable -->
<template>
    <div>
      <div class="grid-x grid-padding-x pad-top">
        <div class="cell small12">
          <div class="bordered pad">
            <div class="grid-x">
              <div class="cell small-12 medium-4">
                <label>Search by first name</label>
                <input type="text" v-model="searchTerm" placeholder="Search by first name">
              </div>
            </div>
            <div class="grid-x">
              <div class="cell small-12 medium-4">
                <button v-on:click="searchLearners" class="button">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-x pad-top">
        <div class="cell small12">
          <div class="bordered pad">
            <table class="user-table">
              <thead>
                <tr>
                  <th class="text-purple">Learner name</th>
                  <th>New worksheet</th>
                  <th>New workplace evidence?</th>
                  <th>New Obs request</th>
                  <th>Actions</th>
                  <th class="text-right">Expand/Contract</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-x pad-top">
        <div class="cell small12">
          <div class="bordered">
          <table class="user-table">
            <tbody>
              <tr
                v-for="(learner, index) in learners"
                v-bind:key="index">
                <td colspan="4">
                  <table class="user-table">
                    <thead>
                      <tr class="user-headline-row">
                        <th>
                          {{ learner.first_name }} {{ learner.last_name }} <br />
                          <span v-for="(tg, tgIndex) in learner.tutor_groups" v-bind:key="tgIndex">
                            {{ tg.name }}
                          </span>
                        </th>
                        <th>
                          <span class="indicator new">{{ learner.new_work_sheets }}</span>
                          New WS
                          <span class="indicator new">
                            {{ learner.new_ws_comments }}
                          </span>
                        </th>
                        <th>
                          <span class="indicator new">{{ learner.new_files }}</span>
                          New WE
                          <span class="indicator new">
                            {{ learner.new_f_comments }}
                          </span>
                        </th>
                        <th>
                          <span class="indicator new">{{ learner.obs_requests }}</span>
                          New OR
                          <span class="indicator new">
                            {{ learner.new_or_comments }}
                          </span>
                        </th>
                        <th>
                          <router-link v-bind:to="`/admin-add-file/${learner.id}`">
                            Add WP-Evidence
                          </router-link>
                          <br />
                          <router-link v-bind:to="`/admin-add-ws/${learner.id}`">
                            Add Coursework
                          </router-link>
                        </th>
                        <th
                          class="link text-right"
                          v-if="openRecord !== learner.id"
                          v-on:click="openRecord = learner.id">Open</th>
                        <th
                          class="link text-right"
                          v-if="openRecord === learner.id"
                          v-on:click="openRecord = 0">
                          Close
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="openRecord === learner.id">
                      <tr>
                        <td>
                          <table>
                            <tr>
                              <td>
                                <router-link v-bind:to="`/messages/${learner.id}`">
                                  Messages
                                </router-link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <router-link v-bind:to="`/view-user/${learner.id}`">
                                  View learner
                                </router-link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <router-link
                                  v-if="learner.matrixes[0]"
                                  v-bind:to="`/view-matrix/${learner.matrixes[0].id}`">
                                  View matrix
                                </router-link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <router-link
                                  v-if="learner.record_of_acheivements[0]"
                                  v-bind:to="`/view-record-of-acheivement/${learner.record_of_acheivements[0].id}`">
                                  View record of acheivement
                                </router-link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <router-link
                                  v-if="learner.certificates[0]"
                                  v-bind:to="`/view-certificate/${learner.certificates[0].id}`">
                                  View certificate
                                </router-link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                &nbsp;
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td colspan="5">
                          <table class="obs-table">
                            <thead>
                              <tr>
                                <th class="underlined-cell">Obs1</th>
                                <th class="underlined-cell">Obs2</th>
                                <th v-if="courseChosen !== 'iqa'" class="underlined-cell">Obs3</th>
                                <th v-if="courseChosen !== 'iqa'" class="underlined-cell">Obs4</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span v-if="learner.observation_requests[0]">
                                    <router-link v-bind:to="`/view-observation-request/${learner.observation_requests[0].id}`">
                                      {{ learner.observation_requests[0].date
                                      | moment("dddd, MMMM Do YYYY") }}<br />
                                      <span
                                        class="indicator passed"
                                        v-if="learner.observation_requests[0].report_url">
                                        1
                                      </span>
                                      <span
                                        class="indicator new"
                                        v-else>
                                        1
                                      </span>
                                    </router-link>
                                  </span>
                                  <span v-else>
                                    <router-link v-bind:to="`/book-observation/${learner.id}/1`">
                                      Book an observation
                                    </router-link>
                                  </span>
                                </td>
                                <td>
                                  <span v-if="learner.observation_requests[1]">
                                    <router-link v-bind:to="`/view-observation-request/${learner.observation_requests[1].id}`">
                                      {{ learner.observation_requests[1].date
                                      | moment("dddd, MMMM Do YYYY") }}<br />
                                      <span
                                        class="indicator passed"
                                        v-if="learner.observation_requests[1].report_url">
                                        2
                                      </span>
                                      <span
                                        class="indicator new"
                                        v-else>
                                        2
                                      </span>
                                    </router-link>
                                  </span>
                                  <span v-else>
                                    <router-link v-bind:to="`/book-observation/${learner.id}/2`">
                                      Book an observation
                                    </router-link>
                                  </span>
                                </td>
                                <td v-if="courseChosen !== 'iqa'">
                                  <span v-if="learner.observation_requests[2]">
                                    <router-link v-bind:to="`/view-observation-request/${learner.observation_requests[2].id}`">
                                      {{ learner.observation_requests[2].date
                                      | moment("dddd, MMMM Do YYYY") }}<br />
                                      <span
                                        class="indicator passed"
                                        v-if="learner.observation_requests[2].report_url">
                                        3
                                      </span>
                                      <span
                                        class="indicator new"
                                        v-else>
                                        3
                                      </span>
                                    </router-link>
                                  </span>
                                  <span v-else>
                                    <router-link v-bind:to="`/book-observation/${learner.id}/3`">
                                      Book an observation
                                    </router-link>
                                  </span>
                                </td>
                                <td v-if="courseChosen !== 'iqa'">
                                  <span v-if="learner.observation_requests[3]">
                                    <router-link v-bind:to="`/view-observation-request/${learner.observation_requests[3].id}`">
                                      {{ learner.observation_requests[3].date
                                      | moment("dddd, MMMM Do YYYY") }}<br />
                                      <span
                                        class="indicator passed"
                                        v-if="learner.observation_requests[3].report_url">
                                        4
                                      </span>
                                      <span
                                        class="indicator new"
                                        v-else>
                                        4
                                      </span>
                                    </router-link>
                                  </span>
                                  <span v-else>
                                    <router-link v-bind:to="`/book-observation/${learner.id}/4`">
                                      Book an observation
                                    </router-link>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td colspan="5">
                          <table class="evidence-table">
                            <thead>
                              <tr>
                                <th class="underlined-cell" colspan="5">Workplace evidence</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colspan="3">
                                  <div class="grid-x grid-padding-x">
                                    <div class="cell small-3 text-center">
                                      <span>New</span>
                                    </div>
                                    <div class="cell small-3 text-center">
                                      <span>Pending</span>
                                    </div>
                                    <div class="cell small-3 text-center">
                                      <span>Passed</span>
                                    </div>
                                    <div class="cell small-3 text-center">
                                        <span>Referred</span>
                                    </div>
                                  </div>
                                </td>
                                <td class="text-right">
                                  <router-link v-bind:to="`/view-user/${learner.id}`">
                                    Go to evidence
                                  </router-link>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">
                                  <div class="grid-x grid-padding-x pad-top--small">
                                    <div class="cell small-3 text-center">
                                      <span class="indicator new">
                                        {{ learner.new_files }}
                                      </span>
                                    </div>
                                    <div class="cell small-3 text-center">
                                      <span class="indicator pending">
                                        {{ learner.pending_files }}
                                      </span>
                                    </div>
                                    <div class="cell small-3 text-center">
                                      <span class="indicator passed">
                                        {{ learner.passed_files }}
                                      </span>
                                    </div>
                                    <div class="cell small-3 text-center">
                                      <span class="indicator referred">
                                        {{ learner.referred_files }}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td colspan="5">
                          <table class="obs-table">
                            <thead>
                              <tr>
                                <th colspan="9">Coursework</th>
                              </tr>
                              <tr>
                                <th class="underlined-cell">WS1</th>
                                <th class="underlined-cell">WS2</th>
                                <th class="underlined-cell">WS3</th>
                                <th class="underlined-cell">WS4</th>
                                <th v-if="courseChosen !== 'iqa'" class="underlined-cell">WS5</th>
                                <th v-if="courseChosen !== 'iqa'" class="underlined-cell">WS6</th>
                                <th v-if="courseChosen !== 'iqa'" class="underlined-cell">WS7</th>
                                <th class="underlined-cell">ILP</th>
                                <th class="underlined-cell">PD</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span v-for="(sheet, index) in learner.worksheets" v-bind:key="index">
                                    <router-link
                                      v-bind:to="`/view-worksheet/${sheet.id}`"
                                      v-if="sheet.title === 'WS1'"
                                      class="indicator"
                                      v-bind:class="{ new: sheet.status === 1, pending: sheet.status === 2, passed: sheet.status === 3, referred: sheet.status === 4 }">
                                      &nbsp;
                                    </router-link>
                                  </span>
                                </td>
                                <td>
                                  <span v-for="(sheet, index) in learner.worksheets" v-bind:key="index">
                                    <router-link
                                      v-bind:to="`/view-worksheet/${sheet.id}`"
                                      v-if="sheet.title === 'WS2'"
                                      class="indicator new"
                                      v-bind:class="{ new: sheet.status === 1, pending: sheet.status === 2, passed: sheet.status === 3, referred: sheet.status === 4 }">
                                      &nbsp;
                                    </router-link>
                                  </span>
                                </td>
                                <td>
                                  <span v-for="(sheet, index) in learner.worksheets" v-bind:key="index">
                                    <router-link
                                      v-bind:to="`/view-worksheet/${sheet.id}`"
                                      v-if="sheet.title === 'WS3'"
                                      class="indicator new"
                                      v-bind:class="{ new: sheet.status === 1, pending: sheet.status === 2, passed: sheet.status === 3, referred: sheet.status === 4 }">
                                      &nbsp;
                                    </router-link>
                                  </span>
                                </td>
                                <td>
                                  <span v-for="(sheet, index) in learner.worksheets" v-bind:key="index">
                                    <router-link
                                      v-bind:to="`/view-worksheet/${sheet.id}`"
                                      v-if="sheet.title === 'WS4'"
                                      class="indicator new"
                                      v-bind:class="{ new: sheet.status === 1, pending: sheet.status === 2, passed: sheet.status === 3, referred: sheet.status === 4 }">
                                      &nbsp;
                                    </router-link>
                                  </span>
                                </td>
                                <td v-if="courseChosen !== 'iqa'">
                                  <span v-for="(sheet, index) in learner.worksheets" v-bind:key="index">
                                    <router-link
                                      v-bind:to="`/view-worksheet/${sheet.id}`"
                                      v-if="sheet.title === 'WS5'"
                                      class="indicator new"
                                      v-bind:class="{ new: sheet.status === 1, pending: sheet.status === 2, passed: sheet.status === 3, referred: sheet.status === 4 }">
                                      &nbsp;
                                    </router-link>
                                  </span>
                                </td>
                                <td v-if="courseChosen !== 'iqa'">
                                  <span v-for="(sheet, index) in learner.worksheets" v-bind:key="index">
                                    <router-link
                                      v-bind:to="`/view-worksheet/${sheet.id}`"
                                      v-if="sheet.title === 'WS6'"
                                      class="indicator new"
                                      v-bind:class="{ new: sheet.status === 1, pending: sheet.status === 2, passed: sheet.status === 3, referred: sheet.status === 4 }">
                                      &nbsp;
                                    </router-link>
                                  </span>
                                </td>
                                <td v-if="courseChosen !== 'iqa'">
                                  <span v-for="(sheet, index) in learner.worksheets" v-bind:key="index">
                                    <router-link
                                      v-bind:to="`/view-worksheet/${sheet.id}`"
                                      v-if="sheet.title === 'WS7'"
                                      class="indicator new"
                                      v-bind:class="{ new: sheet.status === 1, pending: sheet.status === 2, passed: sheet.status === 3, referred: sheet.status === 4 }">
                                      &nbsp;
                                    </router-link>
                                  </span>
                                </td>
                                <td>
                                  <span v-for="(sheet, index) in learner.worksheets" v-bind:key="index">
                                    <router-link
                                      v-bind:to="`/view-worksheet/${sheet.id}`"
                                      v-if="sheet.title === 'ILP'"
                                      class="indicator new"
                                      v-bind:class="{ new: sheet.status === 1, pending: sheet.status === 2, passed: sheet.status === 3, referred: sheet.status === 4 }">
                                      &nbsp;
                                    </router-link>
                                  </span>
                                </td>
                                <td>
                                  <span v-for="(sheet, index) in learner.worksheets" v-bind:key="index">
                                    <router-link
                                      v-bind:to="`/view-worksheet/${sheet.id}`"
                                      v-if="sheet.title === 'PD'"
                                      class="indicator new"
                                      v-bind:class="{ new: sheet.status === 1, pending: sheet.status === 2, passed: sheet.status === 3, referred: sheet.status === 4 }">
                                      &nbsp;
                                    </router-link>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    v-if="learners[index+1]"
                    style="width:100%;border-collapse:collapse">
                    <tr v-if="learner.tutor_groups[0].name !== learners[index+1].tutor_groups[0].name">
                      <td style="border-bottom: 1px solid black" colspan=4>&nbsp;</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-wrap" v-if="chooseCourse === true">
      <div class="modal-inner">
        <p>Which course would you like to access?</p>
        <select v-on:change="selectCourse" v-model="selectedCourse">
          <option value="0">Please choose:</option>
          <option value="iqa">IQA Course</option>
          <option value="ass">Assessing Certificate</option>
          <option value="aa">Assessing Award</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'TutorDashboard',
  data() {
    return {
      messages: [],
      learners: [],
      openRecord: 0,
      searchTerm: '',
      viewMessages: false,
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    userLevel() {
      return this.$store.getters.userLevel;
    },
    courseChosen() {
      return this.$store.getters.courseChosen;
    },
  },
  watch: {
    courseChosen(value) {
      this.chooseCourse = value;
      this.getLearners();
    },
  },
  methods: {
    searchLearners() {
      this.learners = [];
      axios.get(`/users/getUsersForDashboard.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}&searchName=${this.searchTerm}&course=${this.courseChosen}`)
        .then((response) => {
          this.learners = response.data.learners;
        });
    },
    getLearners() {
      this.learners = [];
      axios.get(`/users/getUsersForDashboard.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}&course=${this.courseChosen}`)
        .then((response) => {
          this.learners = response.data.learners;
        });
    },
    getMessages() {
      axios.get(`/messages/getMessages.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.messages = response.data.messages;
          this.sentMessages = response.data.sentMessages;
        });
    },
    checkCourse() {
      this.$store.dispatch('courseNumber', '2');
      if (this.courseChosen === false) {
        this.$store.dispatch('selectCourse', 'ass');
      }
    },
  },
  mounted() {
    this.checkCourse();
    this.getMessages();
    this.getLearners();
  },
};
</script>
